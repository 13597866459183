import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Container, Chip } from '@mui/material'
import { CalendarBlank } from 'phosphor-react'

import {
  NewsArticleQuery,
  // @ts-ignore
} from '__generated__/gatsby-types'

// @ts-ignore
import createNewsEventsCollection from 'helpers/news-events-collection'
import GeneralPage from './layouts/GeneralPage'
import ContentComponents from 'components/molecules/contentComponents/ContentComponents'

interface Props {
  data: NewsArticleQuery
}

const NewsArticle = ({ data }: Props) => {
  const d = data.datoCmsNewsArticle

  const newsEvents = createNewsEventsCollection({
    news: data.allDatoCmsNewsArticle.edges,
    events: data.allDatoCmsEvent.edges,
    limit: 6,
  })

  return (
    <GeneralPage
      keyId={d.originalId}
      imageLandscape={d.imageLandscape.gatsbyImageData}
      imagePortrait={d.imagePortrait.gatsbyImageData}
      heading={d.headingNode.childMarkdownRemark.html}
      seoMetaTags={d.seoMetaTags}
      moreCards={{
        cards: newsEvents,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ pb: { xs: 4, sm: 5, md: 6 }, textAlign: 'center' }}
      >
        <Chip
          color="primary"
          label="News"
          sx={{ marginTop: '6px', marginBottom: '6px' }}
        />
        <Chip
          className="with-icon"
          label={d.formattedDate}
          icon={<CalendarBlank size={18} weight="regular" />}
          sx={{
            backgroundColor: 'lightGrey',
            marginTop: '6px',
            marginBottom: '6px',
          }}
        />
      </Container>
      <ContentComponents
        boxProps={{ pb: 14 }}
        components={d.contentComponents}
        containedWidth="wide"
      />
    </GeneralPage>
  )
}

export default memo(NewsArticle)

export const pageQuery = graphql`
  query NewsArticleQuery($originalId: String!) {
    datoCmsNewsArticle(originalId: { eq: $originalId }) {
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headingNode {
        childMarkdownRemark {
          html
        }
      }
      contentComponents {
        ... on DatoCmsHeading {
          ...contentHeading
        }
        ... on DatoCmsSubHeading {
          ...contentSubHeading
        }
        ... on DatoCmsText {
          ...contentText
        }
        ... on DatoCmsImage {
          ...contentImage
        }
        ... on DatoCmsYoutubeVideo {
          ...contentYouTube
        }
        ... on DatoCmsButton {
          ...button
        }
        ... on DatoCmsDocument {
          ...contentDocument
        }
        ... on DatoCmsButtonGroup {
          ...contentButtonGroup
        }
        ... on DatoCmsLeadText {
          ...contentLeadText
        }
        ... on DatoCmsQuote {
          ...contentQuote
        }
        ... on DatoCmsImageTextSection {
          ...contentImageTextSection
        }
      }
      date
      formattedDate: date(formatString: "Do MMMM, YYYY")
      imageLandscape: heroImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
        )
      }
      imagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "1:1.4", fit: "crop", crop: "focalpoint" }
        )
      }
    }
    allDatoCmsEvent(filter: { originalId: { ne: $originalId } }, limit: 6) {
      edges {
        node {
          ...eventCard
        }
      }
    }
    allDatoCmsNewsArticle(
      filter: { originalId: { ne: $originalId } }
      limit: 6
    ) {
      edges {
        node {
          ...newsCard
        }
      }
    }
    datoCmsGlobalContent {
      moreNewsEventsHeading
    }
  }
`
