import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from '@mui/material'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import {
  DatoCmsSeoMetaTags,
  // @ts-ignore
} from '__generated__/gatsby-types'

import Layout from 'components/organisms/global/Layout'
import ImageHero from 'components/organisms/hero/ImageHero'
import StoryHero from 'components/organisms/hero/StoryHero'
import CurveMask from 'components/atoms/CurveMask'
import SwiperLandingSection from 'components/organisms/sections/SwiperLandingSection'
import { SwiperCardsProps } from 'components/molecules/SwiperCards'

interface Props {
  keyId: string
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  heading: string
  children: React.ReactNode
  moreCards?: SwiperCardsProps
  seoMetaTags: DatoCmsSeoMetaTags
  levelThree?: boolean
}

const GeneralPage = ({
  keyId,
  imageLandscape,
  imagePortrait,
  heading,
  seoMetaTags,
  children,
  moreCards,
  levelThree,
}: Props) => {
  const data = useStaticQuery<GatsbyTypes.GeneralPageQueryQuery>(graphql`
    query GeneralPageQuery {
      datoCmsGlobalContent {
        moreNewsEventsHeading
      }
    }
  `)

  return (
    <Layout
      metadata={{ seoTags: seoMetaTags }}
      forceSolidNav={levelThree ? true : false}
    >
      {levelThree ? (
        <>
          <StoryHero
            contentBgColor="common.white"
            keyId={keyId}
            imageLandscape={imageLandscape}
            imagePortrait={imagePortrait}
            heading={heading}
            compactWidth
            altHeroBg
          />
          <Box
            component="section"
            bgcolor="common.white"
            mt={{ xl: 6 }}
            pt={{ xs: 6 }}
            pb={{ xs: 6, md: 8, lg: 10, xl: 13 }}
            position="relative"
          >
            {children}
          </Box>
        </>
      ) : (
        <>
          <ImageHero
            keyId={keyId}
            imageLandscape={imageLandscape}
            imagePortrait={imagePortrait}
            heading={heading}
            fixedHeight
          />
          <CurveMask
            upShift
            bgColor="common.white"
            containerBoxProps={{
              component: 'section',
            }}
            contentBoxProps={{
              pt: {
                xs: 6,
                sm: 7,
                md: 8,
                lg: 9,
                xl: 10,
              },
            }}
            sunProps={{
              size: 'large',
              position: 'top-left',
              hideBelow: { breakpoint: 'md' },
            }}
          >
            {children}
          </CurveMask>
        </>
      )}

      {moreCards && (
        <SwiperLandingSection
          heading={data?.datoCmsGlobalContent?.moreNewsEventsHeading}
          cards={moreCards.cards}
          sectionBgColor="lightGrey"
          containerBgProps={{
            bgcolor: 'common.white',
          }}
        />
      )}
    </Layout>
  )
}

export default memo(GeneralPage)
